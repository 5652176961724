.modal {
  z-index: 99;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(255,255,255,0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  left: -100%;
  opacity: 0;
  transition: opacity .4s ease-in-out;

  &.active {
    left: 0;
    opacity: 1;
  }

  .modal-content-wrap {
    max-width: 100%;
    width: 700px;
    height: 90vh;
    overflow-x: visible;
    display: flex;
    align-items: center;
    position: relative;

    @include breakpoint(max-width 720px) {
      max-width: calc(100vw - 40px);
    }

    .modal-close {
      position: absolute;
      right: 22px;
      width: 32px;
      height: 32px;
      background: url("../images/close.png") center / 16px 16px no-repeat;
      cursor: pointer;
      margin-top: -50px;

      @include breakpoint(max-width 991px) {
        margin-top: -25px;
        right: 5px;
      }

      @include breakpoint(max-width 499px) {
        margin-top: -20px;
        right: 0;
      }
    }

    .modal-content {
      background-color: #ffffff;
      padding: 80px 60px;
      max-height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: visible;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
      -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);

      @include breakpoint(max-width 991px) {
        padding: 40px 30px;
      }

      @include breakpoint(max-width 499px) {
        padding: 20px 15px;
      }

      h2 {
        font-size: 30px;
        color: $text;
        line-height: 40px;
        font-weight: 300;
        margin-bottom: 40px;

        @include breakpoint(max-width 767px) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      p {
        line-height: 26px;
        color: $text2;
        font-weight: 300;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }

      p.delete-modal {
        text-align: center;
        margin-bottom: 10px;
      }

      ul, ol {
        list-style: disc;
        padding-left: 20px;
        margin-top: 30px;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }

        li {
          line-height: 26px;
          color: $text2;
          font-weight: 300;
        }
      }

      ol {
        list-style: none;
        counter-reset: item;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        ol {
          padding-left: 30px;

          ul {
            list-style: none;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
          }
        }

        li {
          margin-top: 10px;
        }

        > li {
          position: relative;

          &:before {
            position: absolute;
            right: calc(100% + 5px);
            content: counters(item, ".") ". ";
            counter-increment: item;
            font-weight: 500;
          }
        }
      }

      >ol {
        margin-bottom: 30px !important;
      }
    }
  }

  &.modal-receivers {
    .modal-content-wrap {
      .modal-content {
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;


          li {
            width: 40%;
            margin-bottom: 20px;

            @include breakpoint(max-width 499px) {
              width: 100%;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &.modal-reminder {
    .modal-content-wrap {
      width: 560px;

      .modal-content {

        .reminder-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 132px;
            height: auto;
          }

          h2 {
            font-size: 30px;
            width: 100%;
            text-align: center;
            font-weight: 300;
            letter-spacing: 0.025em;
            margin: 30px 0;
          }

          .modal-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .button {
              width: calc(50% - 5px);
            }
          }
        }
      }
    }
  }
}

body.hide-reminder {
  .modal-reminder {
    display: none !important;
  }
}