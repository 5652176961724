@import "reset";
@import "mixins";
@import "breakpoint_mixin";

@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~simplebar/dist/simplebar.css";

@import "cookies";
@import "common";
@import "navbar";
@import "footer";
@import "modal";
@import "accordion";
@import "jq-ui-datepicker";

@import "homepage";
@import "form";
@import "faq";
@import "policy";
@import "contacts";
@import "user";
@import "view";
