.plan-line {
  padding: 25px 0;
  background-color: $orange;

  .container {
    display: flex;
    align-items: center;

    @include breakpoint(max-width 991px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(max-width 599px) {
      flex-direction: column;
    }

    h2 {
      margin-right: auto;
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;

      @include breakpoint(max-width 991px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      @include breakpoint(max-width 767px) {
        font-size: 24px;
      }
    }

    .button {
      text-align: center;
    }

    a {
      margin-left: 10px;

      @include breakpoint(max-width 599px) {
        margin-left: 0;
        margin-top: 10px;
      }

      @include breakpoint(1220px) {
        padding: 0 60px;
      }
    }

    .login-call {
      @include breakpoint(1220px) {
        padding: 0 100px;
      }
    }
  }
}

.section-form {
  overflow: hidden;

  form[name=password_reset] {
    .container {
      position: relative;
    }
  }

  .container {
    h2 {
      color: $text;
      font-size: 30px;
      font-weight: 300;
      position: relative;
      display: inline-block;

      @include breakpoint(max-width 767px) {
        font-size: 24px;
        line-height: 34px;
      }

      .info-message {
        left: calc(100% + 40px);
        top: 50%;
        margin-top: -6px;
      }

      .modal-call {
        min-width: 24px;
        min-height: 20px;
        background: url("../images/modal-call.png") left center / 24px 20px no-repeat;
        position: relative;
        top: 50%;
        margin-top: -10px;
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;
        font-size: 12px;
        color: #adadad;
        font-weight: 300;
        line-height: 20px;
        padding-left: 30px;
      }

      &:not(:first-of-type) {
        margin-top: 60px;
      }
    }

    >p {
      font-size: 18px;
      color: #adadad;
      font-weight: 300;
      margin-top: 10px;
    }

    .reset-wrap {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      margin: 0 auto;
      align-items: center;

      .input-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .reset-success {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      opacity: 0;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .text-credentials-disclaimer {
      margin: 10px 0 0 0;
      padding: 0 10px 0 0;
      font-size: 12px;
    }

    .your-info-inputs {
      margin: 0 -10px;
      display: flex;
      flex-wrap: wrap;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: 0 -11px;
      }

      @include breakpoint(max-width 767px) {
        margin: 0;
      }

      .login-call {
        margin: 20px 10px 0;
      }

      .input-wrap {
        width: calc((100% - 60px) / 3);
        margin: 30px 10px 0;

        @include breakpoint(max-width 767px) {
          width: 100%;
          margin: 30px 0 0;
        }

        &.legal-show {
          display: none;
        }

        &.legal-checkbox {
          label {
            display: inline-block;
          }
        }

        &.checkbox-wrap {
          width: 100%;
        }
      }
    }

    .your-message-inputs, .your-country-inputs {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include breakpoint(max-width 767px) {
        margin: 0;
      }

      h5 {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.025em;
      }

      >.input-wrap {
        &:first-of-type {
          margin: 0 10px;
          width: calc(((100% - 60px) / 3) * 2 + 20px);

          @include breakpoint(max-width 767px) {
            width: 100%;
            margin: 0;
          }
      }

        &:last-of-type {
          margin: 30px 10px 0;
          width: calc(((100% - 60px) / 3));

          @include breakpoint(max-width 767px) {
            width: 100%;
            margin: 30px 0 0;
            height: 300px;
          }
        }
      }

      .radios-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0;

        @include breakpoint(max-width 449px) {
          flex-wrap: wrap;
        }

        .radio-wrap {
          width: 20%;
          display: flex;
          justify-content: center;
          margin-top: 30px;

          @include breakpoint(max-width 449px) {
            width: 50%;
          }

          input[type="radio"] {
            display: none;
          }

          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            img {
              width: auto;
              height: 35px;
              display: inline-block;
            }

            .text {
              display: block;
              text-align: center;
              color: $text;
              font-weight: 300;
              margin: 15px 0 10px;

              @include breakpoint(max-width 1199px) {
                min-height: 32px;
              }
            }

            .rad {
              width: 20px;
              height: 20px;
              display: inline-block;
              position: relative;
              border-radius: 50%;
              background: #f2f2f2;
              border: 1px solid #d5d5d5;

              &:after {
                position: absolute;
                width: 10px;
                height: 10px;
                content: '';
                left: 4px;
                top: 4px;
                background: transparent;
                transition: all .4s ease-in-out;
                border-radius: 50%;
              }
            }
          }

          input[type="radio"]:checked + label span.rad:after {
            background: #e94b25;
          }
        }
      }

      .upload-wrap {
        position: relative;
        height: 400px;

        .drop-input-content {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: center;
        }

        .errmesstoobig {
          position: absolute;
          top: -30px;
          left: 0;
          color: #fe931c;
          font-size: 12px;
          font-weight: 300;
          display: none;
        }

        @include breakpoint(1200px) {
          height: 394px;
        }

        input {
          width: 100%;
          height: calc(100% - 22px);
          cursor: pointer;
          display: none;
        }

        .file-count {
          position: absolute;
          right: 0;
          top: 0;

        }

        .upload-label {
          position: relative;
          width: 100%;
          height: calc(100% - 22px);
          background: #fff url("../images/upload-bg.png") center bottom / 100% auto no-repeat;
          bottom: 0;
          right: 0;
          margin: 0;
          border: 1px solid #d4d4d4;
          display: flex;
          cursor: pointer;
          flex-wrap: wrap;
          align-content: flex-start;
          padding-top: 20px;
          padding-left: 20px;

          &:not(.dz-started) {
            display: flex;
            justify-content: center;
            padding: 0;
          }

          .text-bold {
            text-transform: uppercase;
            margin-top: 60px;
            font-size: 12px;
            font-weight: 700;
            color: $text2;
            line-height: 18px;
            max-width: 90px;
            text-align: center;
            pointer-events: none;
          }

          .text {
            color: #adadad;
            font-weight: 300;
            font-size: 12px;
            margin: 30px 0;
            pointer-events: none;
          }

          .button {
            pointer-events: none;
          }

          @include breakpoint(1200px) {
            &:hover {
              .button {
                background-color: $orange;
                color: #ffffff;
              }
            }
          }

          .dz-preview {
            width: calc(33.33% - 20px);
            height: calc(33.33% - 20px);
            margin: 0 20px 20px 0;
            position: relative;
            background-color: #f2f2f2;
            cursor: default;
            overflow: hidden;

            @include breakpoint(max-width 991px) {
              width: calc(50% - 20px);
              height: calc(20% - 20px);
            }

            @include breakpoint(max-width 767px) {
              width: calc(33.33% - 20px);
              height: calc(33.33% - 20px);
            }

            .dz-progress, .dz-error-message, .dz-success-mark, .dz-error-mark {
              display: none;
            }

            .dz-details {
              color: #1c1d1e;

              .dz-size {
                display: none;
              }

              .dz-filename {
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 5px 16px 5px 5px;
                left: 0;
                top: 0;
                font-size: 14px;
                display: flex;
                align-items: center;
                word-break: break-all;
              }
            }

            .dz-remove {
              position: absolute;
              top: 0;
              right: 0;
              background: #fff url("../images/close.png") center / 8px 8px no-repeat;
              width: 16px;
              font-size: 0;
              height: 16px;
            }

            .dz-image {
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;

              img {
                position: absolute;
                width: auto;
                height: auto;
                min-height: 100%;
                min-width: 100%;
                display: block;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }

            &:not(.dz-file-preview) {
              .dz-details {
                display: none;
              }
            }

            &.dz-file-preview {
              border: 1px solid #d4d4d4;

              .dz-image {
                display: none;
              }
            }
          }

          &.dz-started {

            .text-bold, .text, .button {
              display: none;
            }
          }
        }
      }
    }

    .your-channels-inputs, .your-rubric-inputs, .your-region-inputs {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .wrap-error {
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(100% + 20px);
        color: #fe931c;
        font-size: 12px;
        font-weight: 300;
        align-items: center;
        display: none;

        &.active {
          display: flex;
        }

        img {
          width: 12px;
          height: auto;
          display: block;
          margin-right: 10px;
        }
      }

      .input-wrap {
        width: 20%;

        &.full-width {
          width: 100%;
        }

        @include breakpoint(max-width 991px) {
          width: 25%;
        }

        @include breakpoint(max-width 767px) {
          width: 33.33%;
        }

        @include breakpoint(max-width 499px) {
          width: 50%;
        }
      }
    }

    .your-country-inputs {
      max-width: 392px;
      width: 33%;
      margin: 0;

      .country-wrap {
        margin: 5px 0 0 0;

        .radio-wrap {
          margin-right: 40px;
          min-width: 110px;
          justify-content: unset;

          label {
            flex-direction: row-reverse;
          }

          span.text {
            margin: 0 5px 0 15px;
            min-height: unset;
          }

          span.rad {
          }
        }
      }
    }

    .show-region {
      display: none;

      &.active {
        display: flex;
      }
    }

    .spacer {
      width: 100%;
      height: 4px;
      background-color: #f2f2f2;
      margin: 60px 0 30px;
    }

    .receiver-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;

      @include breakpoint(max-width 849px) {
        flex-direction: column;
        align-items: center;

        h2 {
          width: 100%;
        }
      }

      .button {
        margin-left: 10px;
        white-space: nowrap;

        @include breakpoint(max-width 849px) {
          margin-top: 20px;
        }
      }
    }

    #add-emails {
      width: 100%;
      min-height: 50px;
      padding: 0 17px;
      border: 1px solid #d4d4d4;
      background-color: #fff;
      color: $text;
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
      -webkit-appearance: none;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;

      li.tagit-new {
        height: 30px;
        margin: 10px 0;
        float: none;
        padding: 0 20px 0 10px;

        input {
          padding: 0;
          margin-left: 0;
          height: 100%;
          border: 0;
          background-color: #fff;
        }
      }

      li.tagit-choice-editable {
        height: 30px;
        margin: 10px 0;
        float: none;
        display: flex;
        align-items: center;
        padding: 0 10px 0 10px;
        border: 1px solid #d4d4d4;
        background: #f2f2f2;

        .tagit-label {
          color: $text;
          font-size: 13.33px;
          font-weight: 300;
          margin-right: 6px;
        }
      }

      li:not(:last-of-type) {
        margin-right: 10px;
      }

      li.tagit-choice .tagit-close .text-icon {
        display: block;
      }

      li.tagit-choice .tagit-close {
        right: 6px;
      }
    }

    .schedule-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;

      @include breakpoint(max-width 767px) {
        flex-direction: column;
      }

      .checkbox-wrap {
        margin-top: 0;

        @include breakpoint(768px) {
          padding-right: 40px;
        }

        @include breakpoint(max-width 767px) {
          margin-bottom: 20px;
        }

        label {
          font-size: 30px;
          line-height: 36px;

          @include breakpoint(max-width 767px) {
            font-size: 20px;
            line-height: 26px;
          }

          >div {
            max-width: calc(100% - 40px);
          }

          .info-message {
            position: relative;

            .info-content {
              width: auto;

              &:before, &:after {
                left: 50%;
              }

              p {
                width: 200px;
              }
            }
          }
        }
      }

      .time-selection {
        display: flex;

        &.prevented {
          pointer-events: none;
          opacity: 0.6;
        }

        @include breakpoint(max-width 1099px) {
          flex-direction: column;
        }

        .input-wrap {
          display: flex;
          align-items: center;
          margin-top: 0;

          &:first-of-type {
            margin-right: 60px;

            @include breakpoint(max-width 1099px) {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }

          label {
            font-size: 16px;
            color: $text;
            width: auto;
            margin: 0;
            margin-right: 20px;
            white-space: nowrap;
          }

          input {
            width: 140px;
          }
        }
      }
    }

    .rules-inputs {
      display: flex;
      flex-direction: column;
      align-items: center;

      .input-wrap {
        label {
          span.rules {
            border-bottom: 4px solid #f87e62;
            margin-bottom: -4px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }

      .grec-wrap {
        margin-top: 30px;

        .error-message, .form-error {
          display: none;
          color: #fe931c;
          font-size: 12px;
          font-weight: 300;
          margin-top: 10px;
          text-align: center;
        }

        .form-error {
          display: block;
        }

        &.not-valid {
          .error-message {
            display: block;
          }
        }
      }
    }

    .pay-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;

      .voucher-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;

        .voucher-trigger {
          font-size: 12px;
          font-weight: 600;
          color: $orange;
          padding-left: 24px;
          position: relative;
          cursor: pointer;

          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            background: url("../images/voucher.png") center / cover no-repeat;
            width: 14px;
            height: 14px;
            content: '';
            margin-top: -7px;
          }
        }

        .voucher-input-wrap {
          margin-top: 25px;
          display: none;

          &.active {
            display: flex;

            @include breakpoint(max-width 399px) {
              flex-direction: column;
            }
          }

          .input-wrap {
            width: 160px;
            margin: 0;
          }

          .button {
            margin-left: 10px;

            @include breakpoint(max-width 399px) {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }

        .voucher-success, .voucher-error {
          height: 49px;
          display: none;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
          color: $orange;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .pay-block {
        width: 340px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        border: 1px solid #fbbeb0;

        @include breakpoint(max-width 499px) {
          width: 280px;
        }

        .price-old {
          height: 50px;
          border-radius: 50px;
          padding: 0 30px;
          display: flex;
          align-items: center;
          border: 1px solid #ebebeb;
          position: relative;
          color: $text2;
          font-weight: 300;

          &:after, &:before {
            top: 100%;
            left: 75%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #fff;
            border-width: 9px;
            margin-left: -9px;
          }
          &:before {
            border-color: rgba(235, 235, 235, 0);
            border-top-color: #ebebeb;
            border-width: 10px;
            margin-left: -10px;
          }
        }

        h2 {
          margin: 30px 0 40px;
          color: $orange;
          font-weight: 300;
          font-size: 72px;
        }

        .button {
          font-family: 'Rubik', sans-serif;

          img {
            width: 9px;
            height: auto;
            margin-right: 10px;
          }
        }
        .button.button_draft {
          background-color: #3e90fa;
        }
      }

      .banks-block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        flex-wrap: wrap;

        img {
          width: auto;
          height: auto;
          max-width: 100px;
          max-height: 15px;
          display: block;
          margin: 0 35px;

          @include breakpoint(max-width 991px) {
            margin: 10px 35px;
          }

          @include breakpoint(max-width 767px) {
            margin: 10px;
          }
        }
      }
    }
  }
}

.form-success-wrap {
  padding: 150px 0;

  @include breakpoint(max-width 991px) {
    padding: 125px 0 75px;
  }

  @include breakpoint(max-width 767px) {
    padding: 75px 0 75px;
  }

  .container {

    .form-success {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(max-width 991px) {
          width: 30%;
        }

        @include breakpoint(max-width 767px) {
          width: 100%;
        }

        img {
          display: block;
          width: 55%;
          height: auto;

          @include breakpoint(max-width 991px) {
            width: 70%;
          }

          @include breakpoint(max-width 767px) {
            max-width: 250px;
            margin: 0 auto;
          }
        }
      }

      .right {
        width: 50%;
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(max-width 991px) {
          width: 70%;
        }

        @include breakpoint(max-width 767px) {
          width: 100%;
          align-items: center;
          padding-left: 0;
          text-align: center;
        }

        h1 {
          font-size: 48px;
          color: $text;
          font-weight: 300;

          @include breakpoint(max-width 767px) {
            font-size: 30px;
          }
        }

        p {
          font-weight: 300;
          color: $text2;
          font-size: 18px;
          margin: 20px 0 30px;
          max-width: 450px;
        }
      }
    }
  }
}


.ai-line {
  padding: 25px 0;
  background-color: $orange;
  margin-top: 50px;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    @include breakpoint(max-width 991px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(max-width 599px) {
      flex-direction: column;
    }

    h3 {
      margin-right: auto;
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
      margin: auto;

      @include breakpoint(max-width 991px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      @include breakpoint(max-width 767px) {
        font-size: 24px;
      }
    }

    .ai-buttons {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 30px;
      @include breakpoint(max-width 599px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .button {
      text-align: center;
    }

    .button-hide {
      text-align: center;
      border: 1px solid #ffffff;
      color: #ffffff;
      background-color: $orange;
    }

    a {
      margin-left: 10px;

      @include breakpoint(max-width 599px) {
        margin-left: 0;
        margin-top: 10px;
      }

      @include breakpoint(1220px) {
        padding: 0 60px;
      }
    }

    .login-call {
      @include breakpoint(1220px) {
        padding: 0 100px;
      }
    }
  }
}

.form-progress {
  height: 20px;
  background: #ffb9a9;
  margin-bottom: 20px;
  margin-top: 20px;
  .form-progress-completed {
    background: $orange;
    height: inherit;
  }
}

.ai-form-questions {
  display: none;
  .question {
    font-size: 20px;
  }
  .input-wrap textarea {
    height: 200px;
  }
  &.active {
    display: block;
  }
  .ai-form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
    gap: 20px;

    .ai-back {
      text-align: center;
      border: 1px solid $orange;
      color: $orange;
      background-color: #fff;
    }
  }
  &.first {
    .ai-back {
      display: none;
    }
  }
  &.last {
    .ai-next {
      display: none;
    }
  }
}

.ai-row {
  display: flex;
  gap: 30px;

  .input-wrap {
    width: 100%;
  }

  .ai-regenerate {
    align-self: end;
    white-space: nowrap;
    display: none;
    &.active{
      display: flex;
    }
  }

  .regenerate-content {
    align-self: start;
    margin-top: 52px;
  }
}


.ai-loading-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  display: none;
}

.ai-loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $orange;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $orange transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}