.user-settings-hero {
  padding-top: 90px;

  @include breakpoint(max-width 767px) {
    padding-top: 60px;
  }

  .container {

    h1 {
      color: $text;
      font-size: 48px;
      font-weight: 300;
      letter-spacing: 0.025em;
      padding: 40px 0 40px;

      @include breakpoint(max-width 767px) {
        font-size: 36px;
      }
    }
  }
}

.section-settings {
  .container {
    .settings-line {
      padding: 60px 0;
      border-top: 4px solid #f2f2f2;

      @include breakpoint(max-width 767px) {
        padding: 30px 0;
      }

      h2 {
        color: $text;
        font-size: 30px;
        font-weight: 300;
        position: relative;
        display: inline-block;
      }

      .inputs-wrap {
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin: 0 -11px;
        }

        @include breakpoint(max-width 767px) {
          margin: 0;
        }

        .input-wrap {
          width: calc((100% - 60px) / 3);
          margin: 30px 10px 0;

          @include breakpoint(max-width 767px) {
            width: 100%;
            margin: 30px 0 0;
          }
        }
      }

      .button {
        margin: 40px auto 0;
        padding: 0 70px;
      }

      .plan-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        h2 {
          margin-bottom: 30px;
        }

        h5 {
          font-size: 18px;
          color: $text2;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0.025em;
        }

        .left {
          margin: 0 10px;
          width: calc(((100% - 60px) / 3));

          .current-plan-block {
            border-radius: 8px;
            border: 1px solid #fbbeb0;
            padding: 40px;
            background: url("../../images/hand.png") right 20px top -20px / 120px 130px no-repeat;

            @include breakpoint(768px 1199px) {
              background: none;
            }

            @include breakpoint(max-width 449px) {
              background: none;
              text-align: center;
            }

            .price {
              font-size: 48px;
              font-weight: 300;
              margin-bottom: 25px;
              color: $orange;
            }

            h6 {
              font-size: 12px;
              color: #5a5a5a;
              font-weight: 300;
              margin-bottom: 5px;
            }

            .date {
              font-size: 18px;
              color: $orange;
            }
          }
        }

        .right {
          margin: 0 10px;
          width: calc(((100% - 60px) / 3) * 2 + 20px);

          .invoice-line {
            &:not(:last-of-type) {
              margin-bottom: 15px;
            }

            .date {
              color: $text2;
              padding-left: 15px;
              background: url("../../images/time.png") left center / 8px 8px no-repeat;
              display: inline-block;
              font-weight: 300;
            }

            a {
              display: inline-block;
              color: $text;
              margin-left: 15px;
            }
          }

          .order-plan-block {
            border-radius: 8px;
            border: 1px solid #fbbeb0;

            .top {
              padding: 60px 40px 40px;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              @include breakpoint(max-width 1099px) {
                padding: 30px 20px 20px;
              }

              .top-left {
                width: 48%;
                display: flex;
                flex-direction: column;

                @include breakpoint(max-width 767px) {
                  width: 100%;
                  margin-bottom: 30px;
                }

                @include breakpoint(max-width 499px) {
                  margin-bottom: 50px;
                }

                .price {
                  font-size: 70px;
                  color: $orange;
                  font-weight: 300;
                  letter-spacing: -0.04em;

                  @include breakpoint(max-width 1199px) {
                    font-size: 60px;
                    text-align: center;
                  }

                  @include breakpoint(max-width 599px) {
                    font-size: 40px;
                    text-align: center;
                  }
                }

                .button {
                  position: relative;

                  @include breakpoint(max-width 599px) {
                    margin-top: 20px;
                  }

                  .info-message {
                    @include breakpoint(500px) {
                      top: 50%;
                      left: calc(100% + 20px);
                      margin-left: 0;
                      margin-top: -4px;
                    }
                  }
                }
              }

              .top-right {
                width: 48%;
                display: flex;
                align-items: center;

                @include breakpoint(max-width 767px) {
                  width: 100%;
                }

                ul {
                  li {
                    color: $text2;
                    position: relative;
                    padding-left: 35px;
                    line-height: 22px;
                    letter-spacing: 0.025em;
                    font-weight: 300;
                    font-size: 14px;

                    strong {
                      font-weight: 400;
                      color: #5a5a5a;
                    }

                    &:not(:last-of-type) {
                      margin-bottom: 10px;
                    }

                    &:before {
                      background: url("../../images/check.png") center / cover no-repeat;
                      width: 14px;
                      height: 11px;
                      content: '';
                      position: absolute;
                      left: 0;
                      top: 7px;
                    }
                  }
                }
              }
            }

            .bottom {
              padding: 20px 40px;
              background: #f2f2f2;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              display: flex;
              justify-content: space-between;

              @include breakpoint(max-width 1099px) {
                padding: 20px;
              }

              @include breakpoint(max-width 767px) {
                flex-direction: column;
                align-items: center;
              }

              p {
                font-size: 12px;
                color: #5a5a5a;
                line-height: 22px;

                @include breakpoint(max-width 767px) {
                  margin-bottom: 10px;
                  text-align: center;
                }
              }

              >div {
                display: flex;

                @include breakpoint(max-width 449px) {
                  flex-direction: column;
                  align-items: center;
                }
              }

              a {
                height: 22px;
                line-height: 22px;
                font-size: 12px;
                color: #5a5a5a;
                display: flex;
                position: relative;
                padding-left: 37px;

                &:first-of-type {
                  @include breakpoint(450px) {
                    margin-right: 30px;
                  }

                  @include breakpoint(max-width 449px) {
                    margin-bottom: 10px;
                  }
                }

                img {
                  left: 0;
                  top: 0;
                  position: absolute;
                  max-width: 22px;
                  max-height: 22px;
                  width: auto;
                  height: auto;
                  margin-right: 15px;
                  display: inline-block;
                }
              }
            }
          }
        }

        &:not(.active) {
          @include breakpoint(max-width 991px) {
            margin: 0;
          }

          .left, .right {
            @include breakpoint(max-width 991px) {
              width: 100%;
              margin: 0 0 30px;
            }
          }
        }

        &.active {
          @include breakpoint(max-width 767px) {
            margin: 0;
          }

          .left, .right {
            @include breakpoint(max-width 991px) {
              width: calc(((100% - 40px) / 2));
            }

            @include breakpoint(max-width 767px) {
              width: 100%;
              margin: 0 0 30px;
            }
          }
        }
      }
    }
  }
}
