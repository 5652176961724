body {
  font-family: 'Rubik', sans-serif;

  &.fixed {
    height: 100%;
    overflow: hidden;
  }
}

$text: #1c1d1e;
$text2: #777778;
$orange: #e94b25;
$orange2: #f87e62;

$laikrasciai: #e94b25;
$naujienu_agenturos: #c93618;
$portalai: #f87e62;
$radijo_stotys: #777778;
$regionine_ziniasklaida: #b2b2b2;
$tinklarasciai: #97220b;
$tv: #ffb6a5;
$zurnalai: #e2e2e2;

.color-plain {
  color: #1c1d1e;
}

.text-primary-grey {
  line-height: 26px;
  letter-spacing: 0.025em;
  color: #777778;
  margin-bottom: 30px;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.07), 0 6px 30px 5px rgba(0,0,0,.07), 0 8px 10px -5px rgba(0,0,0,.2);

  @include breakpoint(1460px) {
    //width: 1440px;
  }

  &.min-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .footer {
      margin-top: auto;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include breakpoint(768px) {
    padding: 0 40px;
  }

  @include breakpoint(1220px) {
    width: 1200px;
    padding: 0;
  }
}

.inner-hero {
  height: 360px;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  @include breakpoint(max-width 767px) {
    min-height: 0;
    padding: 90px 0 30px;
  }

  .container {
    position: relative;

    h1 {
      font-size: 48px;
      color: $text;
      letter-spacing: 0.025em;
      font-weight: 300;

      @include breakpoint(max-width 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 300;
      color: $text2;
      max-width: 400px;
      margin: 40px auto 0;

      @include breakpoint(max-width 767px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .hero-inner {
    position: absolute;
    width: 50%;
    height: auto;
    bottom: 0;
    right: 0;
  }

  &.contacts-hero {
    .hero-inner {
      width: 75%;
    }
  }

  &.duk-hero {
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;

    //@include breakpoint(max-width 599px) {
    //  background-size: cover;
    //}
  }
}

.has-shadow-bottom {
  -webkit-box-shadow: inset 0px -30px 25px -20px rgba(119,119,120,0.15);
  -moz-box-shadow: inset 0px -30px 25px -20px rgba(119,119,120,0.15);
  box-shadow: inset 0px -30px 25px -20px rgba(119,119,120,0.15);
}
.has-shadow-top {
  -webkit-box-shadow: inset 0px 30px 25px -20px rgba(119,119,120,0.15);
  -moz-box-shadow: inset 0px 30px 25px -20px rgba(119,119,120,0.15);
  box-shadow: inset 0px 30px 25px -20px rgba(119,119,120,0.15);
}

.label-required {
  &:after {
    margin-left: 1px;
    content: '*';
    color: #f87e62;
  }
}

.button {
  height: 50px;
  border-radius: 50px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  background-color: $orange2;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
  -moz-box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
  box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
  transition: all .4s ease-in-out;
  letter-spacing: 0.12em;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: darken($orange2, 10%);
  }

  &.bordered {
    color: $orange;
    border: 1px solid $orange;
    background-color: #fff;

    &:hover {
      background-color: $orange;
      color: #ffffff;
    }
  }

  &.inverted {
    color: $orange;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(119,119,119,0.15);
    -moz-box-shadow: 0px 5px 20px 0px rgba(119,119,119,0.15);
    box-shadow: 0px 5px 20px 0px rgba(119,119,119,0.15);

    &:hover {
      background-color: darken(#fff, 10%);
    }
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid #c1c1c1;
    color: $text2;
    box-shadow: none;

    &:hover {
      background-color: #c1c1c1;
      color: #ffffff;
    }

    &.orange {
      border: 1px solid $orange2;
      color: $orange2;

      &:hover {
        background-color: $orange2;
        color: #ffffff;
      }
    }

    &.white {
      border: 1px solid #fff;
      color: #fff;

      &:hover {
        background-color: $orange2;
        border-color: $orange2;
        color: #ffffff;
      }
    }
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.preview-button {
  margin-bottom: 10px;
}

.input-wrap {
  margin-top: 30px;
  position: relative;

  >label {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: #adadad;
    font-weight: 300;
    margin-bottom: 10px;
    position: relative;

    .info-message {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      top: 0;
      left: 0;

      @include breakpoint(max-width 1199px) {
        position: static;

        .info-content {
          width: 100%;

          &:before, &:after {
            left: 5%;
          }

          p {
            width: auto;
          }
        }
      }
    }
  }

  input[type=number],
  input[type=password],
  input[type=text],
  input[type=tel],
  input[type=email],
  textarea {
    width: 100%;
    height: 50px;
    padding: 0 17px;
    border: 1px solid #d4d4d4;
    background-color: #f2f2f2;
    color: $text;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    -webkit-appearance: none;
    //transition: all .4s ease-in-out;

    &::-webkit-input-placeholder {
      color: $text2;
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: $text2;
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
    }
    &:-ms-input-placeholder {
      color: $text2;
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
    }
    &:-moz-placeholder {
      color: $text2;
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
      -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
      box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
      -webkit-text-fill-color: $text !important;

      &:hover, &:focus, &:active {
        -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
        -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
        box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #f2f2f2;
        -webkit-text-fill-color: $text !important;
      }
    }
  }

  textarea {
    height: 270px;
    resize: none;
    padding: 17px;
    display: block;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus,
  span:focus {
    outline: 0 !important;
  }

  &.checkbox-wrap {
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      width: auto;
      font-size: 16px;
      margin: 0;
      padding: 0;
      cursor: pointer;
      color: $text;
      @include noselect;
      line-height: 20px;
      position: relative;
      display: flex;
      align-items: center;

      .info-message {
        position: static;
        margin-left: 15px;

        .info-content {
          width: 100%;

          &:after, &:before {
            left: 20%;
          }

          p {
            width: auto;
          }
        }
      }
    }
    input[type="checkbox"] + label span:not(.rules) {
      display: inline-block;
      background-color: #f2f2f2;
      width: 20px;
      height: 20px;
      margin: 0 15px 0 0;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid #d4d4d4;
    }

    input[type="checkbox"]:checked + label span:not(.rules) {
      background: #f2f2f2 url("../images/inputChecked.png") center / 12px 10px no-repeat;
    }

    &.not-valid {
      input[type="checkbox"] + label span:not(.rules) {
        background-color: #fff0df;
        border-color: #fe931c;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .error-message {
    position: absolute;
    right: 5px;
    top: calc(100% + 8px);
    color: #fe931c;
    font-size: 12px;
    font-weight: 300;
    display: none;
  }

  &.not-valid {
    input, textarea {
      background: #fff0df url("../images/error.png") right 20px center / 12px 12px no-repeat;
      border-color: #fe931c;
    }

    .error-message {
      display: block;
    }
  }
}

.info-message {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  margin-left: -8px;
  top: calc(100% + 10px);
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f57f64;
  font-size: 8px;
  color: #f57f64;
  text-align: center;
  line-height: 12px;
  font-weight: 700;
  cursor: default;
  display: block;
  @include noselect;

  @include breakpoint(max-width 1199px) {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
  }

  .info-content {
    z-index: 20;
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease-in-out;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);

    &:after, &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 8px;
      margin-left: -8px;
    }
    &:before {
      border-color: rgba(211, 229, 243, 0);
      border-bottom-color: #fff;
      border-width: 9px;
      margin-left: -9px;
    }

    p {
      width: 200px;
      text-align: left;
      margin: 25px;
      color: $text2;
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
    }
  }

  &:hover {
    .info-content {
      opacity: 1;
    }
  }
}

.invisible {
  display: none !important;
}

.ui-corner-all
{
  -moz-border-radius: 4px 4px 4px 4px;
}
.ui-widget-content
{
  border: 1px solid #d4d4d4;
  color: #222222;
}
.ui-widget
{
  font-family: Verdana,Arial,sans-serif;
  font-size: 15px;
}
.ui-menu
{
  display: block;
  float: left;
  list-style: none outside none;
  margin: 0;
  padding: 2px;
}
.ui-autocomplete
{
  cursor: default;
  position: absolute;
}
.ui-menu .ui-menu-item
{
  clear: left;
  float: left;
  margin: 0;
  padding: 2px;
  width: 100%;
}
.ui-menu .ui-menu-item a
{
  display: block;
  padding: 3px 3px 3px 3px;
  text-decoration: none;
  cursor: pointer;
  background-color: Green;
}
.ui-menu .ui-menu-item a:hover, .ui-menu .ui-menu-item:hover
{
  display: block;
  padding: 3px 3px 3px 5px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: #f2f2f2;
}
.ui-widget-content a
{
  color: #222222;
}