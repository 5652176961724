@import "../fontawesome/scss/fontawesome.scss";
@import "../fontawesome/scss/solid.scss";
@import "../fontawesome/scss/brands.scss";

.user-history-hero {
  padding-top: 90px;

  @include breakpoint(max-width 991px) {
    padding-top: 60px;
  }

  .container {

    h1 {
      color: $text;
      font-size: 48px;
      font-weight: 300;
      letter-spacing: 0.025em;
      padding: 40px 0;

      @include breakpoint(max-width 991px) {
        font-size: 24px;
        padding: 20px 0;
        display: none;
      }
    }
  }
}

body.media_message_history {
  overflow: hidden;
}

.section-history {
  overflow-x: hidden;

  .container {
    display: flex;
    position: relative;

    .left {
      width: 30%;
      background-color: #f5f5f5;

      @include breakpoint(max-width 991px) {
        width: 280px;
        position: fixed;
        transform: translateX(-100%);
        left: 0;
        top: 60px;
        z-index: 98;
        transition: all .4s ease-in-out;
      }

      &.active {
        transform: translateX(0);
      }

      .filter-tags {
        background-color: #fff;
        border-left: 1px solid #d4d4d4;
        border-top: 1px solid #d4d4d4;
        transition: all .4s ease-in-out;
        overflow: hidden;

        @include breakpoint(max-width 991px) {
          border-right: 1px solid #d4d4d4;
        }

        .filter-tags-head {
          padding: 30px 0 30px 20px;
          font-weight: 700;
          color: $text2;
          font-size: 12px;
          cursor: pointer;
          text-transform: uppercase;
          background: url("../../images/down-gray.png") right 20px center / 12px 8px no-repeat;
        }

        .filter-tags-body {
          padding: 0 20px 40px 20px;
          background: url("../../images/papers.png") right bottom / cover no-repeat;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .tag {
            font-weight: 300;
            color: $text2;
            line-height: 1;
            transition: color .4s ease-in-out;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .tagname {
              display: inline-block;
              cursor: pointer;
            }

            .tag-delete {
              color: $orange;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.025em;
              line-height: 16px;
              display: flex;
              align-items: center;
              padding-left: 10px;
              cursor: pointer;
            }

            &:not(:last-of-type) {
              margin-bottom: 10px;
            }

            @include breakpoint(1200px) {
              &:hover {
                color: $orange;
              }
            }

            &.active {
              color: $orange;

              .tagname {
                padding-right: 20px;
                font-weight: 500;
                background: url("../../images/close-smaller.png") right center / 11px 11px no-repeat;
              }
            }
          }

          .add-new {
            margin-top: 30px;
            color: $orange;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 20px;
            background: url("../../images/plus.png") left center / 13px 13px no-repeat;
            cursor: pointer;
            @include noselect;
          }
        }
      }

      .history-grid {
        border-left: 1px solid #d4d4d4;
        max-height: calc(100vh - 218px);
        //overflow-y: auto;
        overflow: hidden;

        .relative {
          position: relative;

          .del-btn-position {
            position: absolute;
            bottom: 25px;
            right: 25px;
            z-index: 1;
            cursor: pointer;
          }
        }

        @include breakpoint(max-width 991px) {
          height: calc(100vh - 60px);
          max-height: calc(100vh - 60px);
          background-color: #fff;
        }

        .del-btn-position {
          color: #3e90fa;
        }

        .list-edit {
          position: absolute;
          z-index: 10;
          top: 25px;
          right: 20px;

          a {
            color: #3e90fa;
          }
        }

        .single-history {
          padding: 20px 40px 20px 20px;
          position: relative;
          border-top: 1px solid #d4d4d4;
          background-color: transparent;
          cursor: pointer;
          transition: all .4s ease-in-out;
          display: block;

          @include breakpoint(max-width 991px) {
            padding: 10px 20px 10px 10px;
          }

          &:last-of-type {
            border-bottom: 1px solid #d4d4d4;
          }

          h5 {
            color: $text;
            position: relative;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.025em;

            @include breakpoint(max-width 991px) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          .status {
            display: inline-block;
            padding: 5px 20px;
            background-color: $orange;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 5px;
            margin: 10px 0;
          }

          .status.status_confirmed {
            background-color: #f87e62;
          }


          .status.status_draft {
            display: inline-block;
            padding: 5px 20px;
            background-color: #3e90fa;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 5px;
            margin: 10px 0;
          }

          .status.status_waiting {
            display: inline-block;
            padding: 5px 20px;
            background-color: #ffb900;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 5px;
            margin: 10px 0;
          }

          .btn-cancel-scheduled {
            display: inline-block;
            padding: 5px 20px;
            background-color: $orange2;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 5px;
            margin: 10px 0;
          }

          .tag-time {
            display: block;

            .tag {
              font-size: 12px;
              font-weight: 500;
              color: $orange;
              margin-right: 15px;
              display: inline-block;
            }
          }

          .time {
            display: inline-block;
            color: #adadad;
            font-size: 12px;
            font-weight: 300;
            padding-left: 17px;
            background: url("../../images/time.png") left center / 9px 9px no-repeat;

            @include breakpoint(max-width 991px) {
              margin-top: 10px;
            }
          }

          &:hover, &.active {
            background-color: #e2e2e2;
          }
        }
      }
    }

    .right {
      width: 70%;
      position: relative;

      @include breakpoint(max-width 991px) {
        width: 100%;
      }

      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        top: 0;
        left: 0;
        pointer-events: none;
        -webkit-box-shadow: inset 41px 0 67px -61px rgba(0,0,0,0.7);
        -moz-box-shadow: inset 41px 0 67px -61px rgba(0,0,0,0.7);
        box-shadow: inset 41px 0 67px -61px rgba(0,0,0,0.7);
        z-index: 5;

        @include breakpoint(max-width 991px) {
          display: none;
        }
      }

      .content {
        max-height: calc(100vh - 218px);
        overflow-y: auto;

        @include breakpoint(max-width 991px) {
          max-height: calc(100vh - 60px);
          overflow: visible;

          .simplebar-track {
            right: -20px;
          }
        }

        .top-info {
          position: relative;
          padding: 60px;

          @include breakpoint(max-width 1199px) {
            padding: 30px;
          }

          @include breakpoint(max-width 991px) {
            padding: 30px 0;
          }

          .edit_button {
            border-radius: 5px;
          }

          .edit_button_draft {
            background-color: #3e90fa;
            color: #ffffff;
          }

          .edit_button_waiting {
            background-color: #ffb900;
            color: #ffffff;
          }

          .monitoring-results {
            position: relative;
            padding-bottom: 60px;
            margin-bottom: 60px;
            border-bottom: 4px solid #e8e8e8;

            h2 {
              font-size: 30px;
              font-weight: 300;
              color: $text;
              display: flex;
              align-items: center;

              @include breakpoint(max-width 1199px) {
                font-size: 24px;
              }
            }

            .results-grid {
              display: flex;
              flex-wrap: wrap;

              .result-single {
                width: 50%;
                padding-right: 50px;
                margin-top: 30px;

                @include breakpoint(max-width 599px) {
                  width: 100%;
                  padding: 0 !important;
                  margin-top: 15px;
                }

                &:nth-of-type(2n) {
                  padding-left: 50px;
                  padding-right: 0;
                }

                a {
                  color: #1c1d1e;
                  font-size: 16px;
                  line-height: 26px;
                }

                .result-info {
                  margin-top: 10px;

                  .publ {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 12px;
                    color: #777778;
                    margin-right: 20px;
                  }

                  .date {
                    display: inline-block;
                    color: #adadad;
                    font-size: 12px;
                    font-weight: 300;
                    padding-left: 17px;
                    background: url('../../images/time-gray.png') 0/9px 9px no-repeat;
                  }
                }
              }
            }
          }

          .chart {
            display: flex;
            justify-content: space-between;

            .btn {
              color: white;
            }

            .relative
            {
              position: relative;
            }

            @include breakpoint(max-width 1199px) {
              flex-wrap: wrap;
            }

            .legend_wrap
            {
                display: flex;
            }

            .legend {
              position: relative;
              margin-right: 80px;

              @include breakpoint(max-width 1199px) {
                width: 50%;
                margin-right: 0;
              }

              @include breakpoint(max-width 499px) {
                width: 100%;
                margin-bottom: 20px;
              }

              h5 {
                font-size: 18px;
                font-weight: 300;
                color: $text;
                margin-bottom: 20px;
              }

              ul {
                li {
                  font-size: 16px;
                  font-weight: 300;
                  color: $text2;
                  position: relative;
                  padding-left: 13px;

                  &:before {
                    position: absolute;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: $text2;
                    content: '';
                    left: 0;
                    top: 50%;
                    margin-top: -1px;
                  }

                  &.laikrasciai:before {
                    background-color: $laikrasciai;
                  }
                  &.naujienu_agenturos:before {
                    background-color: $naujienu_agenturos;
                  }
                  &.portalai:before {
                    background-color: $portalai;
                  }
                  &.radijo_stotys:before {
                    background-color: $radijo_stotys;
                  }
                  &.regionine_ziniasklaida:before {
                    background-color: $regionine_ziniasklaida;
                  }
                  &.tinklarasciai:before {
                    background-color: $tinklarasciai;
                  }
                  &.tv:before {
                    background-color: $tv;
                  }
                  &.zurnalai:before {
                    background-color: $zurnalai;
                  }

                  &:not(:last-of-type) {
                    margin-bottom: 10px;
                  }
                }
              }

              &:first-of-type {
                ul {
                  li {
                    padding-left: 25px;

                    &:before {
                      width: 10px;
                      height: 10px;
                      margin-top: -5px;
                    }
                  }
                }
              }
            }

            .pie-chart {
              position: relative;
              min-height: 250px;

              @include breakpoint(max-width 1199px) {
                width: 100%;
                height: 300px;
                margin-top: 20px;
              }

              @include breakpoint(max-width 399px) {
                height: 400px;
                margin-top: 0;
                padding: 0 20px;
              }

              svg {
                overflow: visible;
              }
            }
          }



          .flex-align {
            flex-direction: column;
            align-items:flex-start;

            .display-flex {
              display: flex;
            }
          }

          .receivers-block {
            display: flex;
            position: relative;
            padding: 33px 40px;
            border: 1px solid #d4d4d4;
            border-radius: 9px;
            justify-content: space-between;
            align-items: center;
            margin-top: 60px;

            @include breakpoint(max-width 1199px) {
              margin-top: 30px;
              padding: 30px;
            }

            @include breakpoint(max-width 767px) {
              flex-direction: column;
            }

            h2 {
              font-size: 30px;
              font-weight: 300;
              color: $text;
              display: flex;
              align-items: center;

              @include breakpoint(max-width 1199px) {
                font-size: 24px;
              }

              span {
                font-size: 48px;
                font-weight: 300;
                display: inline-block;
                color: $orange;
                margin-left: 20px;
              }
            }

            .link-icons {
              h2 {
                font-size: 30px;
                font-weight: 300;
                color: $text;
                display: flex;
                align-items: center;

                @include breakpoint(max-width 1199px) {
                  font-size: 24px;
                }

                span {
                  font-size: 20px;
                  font-weight: 300;
                  display: inline-block;
                  color: $orange;
                  margin-left: 20px;
                }
              }
            }

            .button {
              background-color: transparent;
              position: relative;

              @include breakpoint(max-width 767px) {
                margin-top: 20px;
              }

              &:hover {
                background-color: $orange;
              }
            }
          }

          .receivers-list {
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          .message-tags {
            margin-top: 40px;
            position: relative;

            h5 {
              font-size: 18px;
              color: $text;
              margin-bottom: 20px;
              line-height: 1;
            }

            .message-taglist {
              margin-bottom: 20px;

              li {
                display: inline-block;
                font-size: 14px;
                font-weight: 300;
                padding-left: 20px;
                color: $text2;
                margin-bottom: 10px;
                margin-right: 60px;
                cursor: pointer;
                background: url("../../images/close-smaller-gray.png") left 1px / 11px 11px no-repeat;
                transition: all .4s ease-in-out;
                line-height: 1;

                &:hover {
                  background: url("../../images/close-smaller.png") left 1px / 11px 11px no-repeat;
                  color: $orange;
                }
              }
            }

            .attach-tag {
              display: flex;
              justify-content: flex-start;

              @include breakpoint(max-width 499px) {
                flex-direction: column;
                align-items: center;
              }

              *:focus {
                outline: none;
              }

              .select2-container {
                width: 370px !important;
                margin-right: 20px;

                @include breakpoint(max-width 499px) {
                  margin-right: 0;
                  margin-bottom: 20px;
                  width: 250px !important;
                }

                .select2-selection--single {
                  height: 50px;
                  border: 1px solid #d4d4d4;
                  border-radius: 0;

                  .select2-selection__rendered {
                    line-height: 50px;
                    padding-left: 20px;
                    padding-right: 40px;
                    color: $text;

                    .select2-selection__placeholder {
                      color: $text;
                    }
                  }

                  .select2-selection__arrow {
                    height: 50px;
                    width: 40px;
                    background: url("../../images/down.png") left 10px center / 12px 8px no-repeat;

                    b {
                      display: none;
                    }
                  }
                }

                &.select2-container--open {
                  .select2-selection--single {
                    .select2-selection__arrow {
                      transform: rotate(180deg);
                      background: url("../../images/down.png") left 18px center / 12px 8px no-repeat;
                    }
                  }
                }
              }

              .button {
                padding: 0 60px;
              }
            }

            .add-new {
              margin-top: 20px;
              color: $orange;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              padding-left: 20px;
              background: url("../../images/plus.png") left center / 13px 13px no-repeat;
              cursor: pointer;
              @include noselect;
              display: inline-block;
            }
          }

          &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #f5f5f5;
            content: '';
            left: 0;
            top: 0;

            @include breakpoint(max-width 991px) {
              display: none;
            }
          }
        }

        .message-text {
          padding: 60px;

          @include breakpoint(max-width 1199px) {
            padding: 30px;
          }

          @include breakpoint(max-width 991px) {
            padding: 30px 0;
          }

          .title {
            font-size: 30px;
            color: $text;
            line-height: 34px;
            font-weight: 300;
          }

          .message-wrap {
            * {
              margin-top: 20px;
              font-size: 16px;
              line-height: 26px;
              font-weight: 300;
              letter-spacing: 0.025em;
            }

            em {
              font-style: italic;
            }

            strong {
              font-weight: 700;
            }

            ul {
              list-style: disc;
              padding-left: 20px;

              li {
              }
            }

            ol {
              list-style: decimal;
              padding-left: 20px;

              li {
              }
            }
          }
        }

        .sender-info {
          padding: 60px 0;
          width: calc(100% - 120px);
          margin-left: 60px;
          margin-bottom: 80px;
          border-top: 4px solid #f2f2f2;
          border-bottom: 4px solid #f2f2f2;
          display: flex;

          @include breakpoint(max-width 767px) {
            flex-wrap: wrap;
          }

          @include breakpoint(max-width 1199px) {
            padding: 30px 0;
            width: calc(100% - 60px);
            margin-left: 30px;
            margin-bottom: 40px;
          }

          @include breakpoint(max-width 991px) {
            width: 100%;
            margin-left: 0;
          }

          .info-block {
            width: calc(33.33% - 40px);
            margin-right: 40px;
            display: flex;
            align-items: center;

            @include breakpoint(max-width 1199px) {
              width: calc(33.33% - 20px);
              margin-right: 20px;
            }

            @include breakpoint(max-width 767px) {
              width: 100%;
              margin-right: 0;
              flex-direction: column;

              &:not(:last-of-type) {
                margin-bottom: 20px;
              }
            }

            .image {
              width: 50px;
              height: 50px;
              margin-right: 10px;

              @include breakpoint(max-width 1199px) {
                width: 30px;
                height: 30px;
              }

              @include breakpoint(max-width 767px) {
                margin-right: 0;
              }
            }

            &.email .image {
              background: url("../../images/email.png") center / contain no-repeat;
            }
            &.phone .image {
              background: url("../../images/phone.png") center / contain no-repeat;
            }
            &.name .image {
              background: url("../../images/user.png") center / contain no-repeat;
            }

            .text {
              width: calc(100% - 60px);

              @include breakpoint(max-width 1199px) {
                width: calc(100% - 30px);
              }

              @include breakpoint(max-width 767px) {
                width: 100%;
                text-align: center;
                margin-top: 10px;
              }

              h6 {
                font-size: 12px;
                color: #adadad;
                font-weight: 300;
                margin-bottom: 5px;
              }

              p, a {
                font-weight: 300;
                font-size: 14px;
                color: $text;
              }
            }
          }
        }
      }
    }

    .empty {
      height: calc(100vh - 218px);
      width: 100%;
      display: flex;
      align-items: center;

      @include breakpoint(max-width 991px) {
        height: calc(100vh - 60px);
      }

      @include breakpoint(max-width 767px) {
        flex-wrap: wrap;
      }

      .image {
        width: 50%;

        @include breakpoint(max-width 767px) {
          width: 100%;
        }

        img {
          width: 70%;
          max-width: 400px;
          margin: 0 auto;
          display: block;
        }
      }

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 40px;

        @include breakpoint(max-width 767px) {
          width: 100%;
          margin: 30px 0;
          align-items: center;
          text-align: center;
          padding: 0;
        }

        h2 {
          color: $text;
          font-size: 48px;
          font-weight: 300;
          margin-bottom: 25px;
        }

        p {
          color: $text2;
          font-size: 18px;
          line-height: 28px;
          font-weight: 300;
          letter-spacing: 0.025em;
          margin: 25px 0 50px;
        }
      }
    }
  }
}

.media_message_history .footer {
  display: none !important;
}


.select2-container {

  .select2-dropdown {
    border: 1px solid #d4d4d4;
    border-radius: 0;

    .select2-results {

      .select2-results__options {
        max-height: 250px;

        .select2-results__option {
          color: $text2;
          padding: 10px 20px;
          background-color: #fff !important;
          transition: all .4s ease-in-out;
          font-size: 16px;

          &:hover {
            color: $text;
          }

          &[aria-selected=true] {
            background-color: #fff;
            color: $text;
          }
        }
      }
    }
  }
}

.simplebar-track {
  .simplebar-scrollbar {
    opacity: 1 !important;
    background: #d7d7d7;
    cursor: pointer;
  }
}

.modal-removetag {
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2, p {
      width: 100%;
    }

    .button {
      margin-top: 40px;
      padding: 0 60px;
    }
  }
}

.modal-addnew {
  .new-tag {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    @include breakpoint(max-width 499px) {
      flex-direction: column;
      align-items: center;
    }

    form {
      width: 100%;

      .input-wrap {
        margin-top: 0;
      }
    }

    input {
      //width: 100%;
      //height: 50px;
      //padding: 0 17px;
      //border: 1px solid #d4d4d4;
      //background-color: #f2f2f2;
      //color: $text;
      //font-family: 'Rubik', sans-serif;
      //font-weight: 300;
      //-webkit-appearance: none;
    }

    .button {
      padding: 0 60px;
      margin-left: 20px;

      @include breakpoint(max-width 499px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
