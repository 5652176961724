.navbar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  .wrapper {
    height: 60px;
    background-color: #fff;
    transition: all .4s ease-in-out;

    @include breakpoint(1100px) {
      height: 90px;
    }

    .container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: inline-block;
        width: 78px;
        height: 26px;
        background: url('../images/logo.png') center / cover no-repeat;
        position: relative;
      }

      ul.nav {
        display: flex;

        @include breakpoint(1100px) {
          order: 2;
          align-items: center;
          width: calc(100% - 78px);
        }

        @include breakpoint(max-width 1099px) {
          width: 100vw;
          height: auto;
          position: fixed;
          left: 0;
          top: 60px;
          flex-direction: column;
          align-items: center;
          transform: translateY(-120%);
          background-color: #fff;
          transition: all .4s ease-in-out;
          max-height: calc(100vh - 60px);
          overflow-y: auto;

          &.active {
            transform: translateY(0);
          }
        }

        .nav-separator {
          margin: 0 10px 0 0;
        }

        li {
          @include breakpoint(max-width 1099px) {
            margin: 20px 0;
          }

          @include breakpoint(1100px) {
            margin-left: 30px;
          }

          @include breakpoint(1200px) {
            margin-left: 40px;
          }

          &.send {
            @include breakpoint(1100px) {
              margin-left: auto;
            }
          }

          &.login {
            @include breakpoint(1100px) {
              margin-left: 10px;
            }
          }

          &.logged-in {
            margin-left: auto;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            @include breakpoint(1100px) {
              max-width: 350px;
              text-align: right;
            }

            @include breakpoint(1220px) {
              max-width: 450px;
              text-align: right;
            }

            @include breakpoint(max-width 1099px) {
              margin-left: 0;
              flex-direction: column;
              height: auto;
              text-align: center;
            }

            >a {
              padding-right: 22px;
              font-size: 12px;
              color: $orange;
              text-transform: uppercase;
              background: url("../images/down.png") right center / 12px 8px no-repeat;
              font-weight: 700;
            }

            ul {
              position: absolute;
              right: 0;
              top: 100%;
              width: 100%;
              min-width: 150px;
              padding: 20px 0;
              background-color: #fff;
              box-shadow: 0px 0px 44.5px 5.5px rgba(119, 119, 120, 0.15);
              border-radius: 9px;
              opacity: 0;
              pointer-events: none;
              transition: all .4s ease-in-out;

              @include breakpoint(max-width 1099px) {
                position: static;
                opacity: 1;
                pointer-events: auto;
                box-shadow: none;
              }

              &:after {
                bottom: 100%;
                right: 20%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #ffffff;
                border-width: 9px;
                margin-left: -9px;
              }

              li {
                margin: 0;

                a {
                  padding: 10px 0;
                  width: 100%;
                  display: block;
                  font-weight: 500;
                  color: $text2;
                  transition: all .4s ease-in-out;
                  font-size: 12px;
                  text-align: center;

                  img {
                    width: 13px;
                    height: 9px;
                    margin-left: 5px;
                    display: inline-block;
                  }

                  @include breakpoint(1200px) {
                    &:hover {
                      color: $orange;
                    }
                  }
                }
              }
            }

            &:hover {
              ul {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }

          a {
            color: $text;
            font-weight: 300;
            font-size: 14px;

            &.button {
              color: #ffffff;
            }
          }

          .button {
            font-size: 12px;
            height: 40px;
            font-weight: 700;
          }
        }
      }

      .mob-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #fff;

        @include breakpoint(1100px) {
          display: none;
        }
      }

      .history-trigger {
        display: none;
      }

      .trigger {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        cursor: pointer;

        span {
          width: 20px;
          height: 2px;
          background-color: $orange;
          display: block;

          &:nth-of-type(2) {
            margin: 5px 0;
          }
        }

        @include breakpoint(1100px) {
          display: none;
        }
      }
    }
  }

  &.top {
    .wrapper {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.login-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: -100%;
  opacity: 0;
  transition: opacity .4s ease-in-out;
  top: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    opacity: 1;
    left: 0;
  }

  .login-content-wrap {
    width: 300px;
    height: auto;
    max-height: 96vh;
    overflow: auto;
    position: relative;
    overflow-x: hidden;
    box-shadow: 0px 0px 44.5px 5.5px rgba(119, 119, 120, 0.15);

    @include breakpoint(425px) {
      width: 400px;
    }

    @include breakpoint(992px) {
      width: 970px;
    }

    .login-close {
      position: absolute;
      background: url("../images/close.png") center / 16px 15px no-repeat;
      width: 30px;
      height: 30px;
      cursor: pointer;
      top: 10px;
      right: 10px;

      @include breakpoint(425px) {
        top: 15px;
        right: 20px;
      }
    }

    .login-content {
      width: 100%;
      height: 100%;
      overflow: auto;

      @include breakpoint(992px) {
        display: flex;
      }

      .login-side {
        padding: 40px 20px;

        @include breakpoint(425px) {
          padding: 60px 40px;
        }

        h2 {
          font-size: 30px;
          letter-spacing: 0.025em;
          line-height: 1;
          margin-bottom: 10px;
          color: $text;
          font-weight: 300;
          padding-left: 50px;
        }

        .social-login-disclaimer {
          text-align: left;
          font-size: 11px;
          margin-top: 15px;
          margin-bottom: 10px;
          line-height: 22px;
        }

        .fb-login {
          background-color: #3b5998;
          margin-top: 30px;
          font-size: 10px;
          padding: 0 20px;
          width: 100%;
          justify-content: flex-start;
          box-shadow: 0 10px 20px 0 rgba(59,89,152,.3);
          background-image: url("../images/fb-login.png");
          background-position: right 16px bottom;
          background-size: 22px 40px;
          background-repeat: no-repeat;

          @include breakpoint(1200px) {
            &:hover {
              background-color: #394784;
            }
          }
        }

        &.login-login {
          width: 100%;
          background-color: #e4e4e4;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          h2 {
            background: url("../images/login-heading-icon.png") left center / 30px 30px no-repeat;
          }

          &.hidden {
            display: none;
          }

          @include breakpoint(992px) {
            width: 35%;
          }

          form {
            width: 100%;
            position: relative;

            .error-message-login {
              position: absolute;
              bottom: -30px;
              text-align: center;
              width: 100%;
              left: 0;
              color: $orange;
              font-weight: 300;
              font-size: 14px;
            }

            .forgot-pasw {
              font-size: 12px;
              font-weight: 300;
              color: $text2;
              text-align: center;
              width: 100%;
              cursor: pointer;
              @include noselect;
              margin-top: 30px;
            }

            .input-wrap {

              label {
                color: $text2;
              }

              input {
                background-color: #fff;

                &:-webkit-autofill {
                  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  -webkit-text-fill-color: $text !important;

                  &:hover, &:focus, &:active {
                    -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                    -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                    box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                    -webkit-text-fill-color: $text !important;
                  }
                }
              }

              &.checkbox-wrap {

                label {
                  justify-content: center;
                  font-size: 16px;
                  color: $text;
                }
              }
            }

            .button {
              width: 100%;
              margin-top: 20px;

              @include breakpoint(max-width 991px) {
                margin-top: 20px;
              }
            }
          }
        }

        &.login-reminder {
          width: 100%;
          background-color: #e4e4e4;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: none;
          position: relative;

          .back-to-login {
            position: absolute;
            top: 16px;
            left: 20px;
            color: $text;
            letter-spacing: 0.05em;
            font-size: 12px;
            padding-left: 15px;
            cursor: pointer;

            @include breakpoint(992px) {
              top: 24px;
            }

            &:before {
              position: absolute;
              content: '';
              background: url("../images/down.png") center / contain no-repeat;
              width: 8px;
              height: 12px;
              left: 0;
              transform: rotate(90deg);
            }
          }

          .input-wrap {
            label {
              color: $text2;
            }

            input {
              background-color: #fff;

              &:-webkit-autofill {
                -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                -webkit-text-fill-color: $text !important;

                &:hover, &:focus, &:active {
                  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.1), inset 0 0 0px 1000px #fff;
                  -webkit-text-fill-color: $text !important;
                }
              }
            }
          }

          &.active {
            display: flex;
          }

          @include breakpoint(992px) {
            width: 35%;
          }

          h2 {
            background: url("../images/rem-heading-icon.png") left center / 30px 30px no-repeat;
            width: 100%;
          }

          form {
            width: 100%;
          }

          .reminder-response-text {
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;

            .error {
              color: #fe931c;
            }
          }

          .button {
            width: 100%;
            margin-top: 60px;

            @include breakpoint(max-width 991px) {
              margin-top: 30px;
            }
          }
        }

        &.login-reg-trig {
          width: 100%;
          background-color: #fff;
          text-align: center;
          padding: 40px 20px;

          @include breakpoint(425px) {
            padding: 40px 60px;
          }

          &.hidden {
            display: none;
          }

          h5 {
            color: $text2;
            margin-bottom: 5px;
            font-size: 14px;
          }

          h2 {
            padding: 0;
          }

          img {
            width: 12px;
            height: 8px;
          }

          @include breakpoint(992px) {
            display: none;
          }
        }

        &.login-reg {
          width: 100%;
          background-color: #ffffff;
          display: none;
          position: relative;

          &.active {
            display: flex;
            flex-direction: column;
          }

          @include breakpoint(992px) {
            display: flex;
            flex-direction: column;
            width: 65%;
          }

          h2 {
            background: url("../images/reg-heading-icon.png") left center / 30px 30px no-repeat;
          }

          form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            position: relative;

            .error-message-reg {
              position: absolute;
              bottom: -30px;
              text-align: center;
              width: 100%;
              right: 0;
              color: $orange;
              font-weight: 300;
              font-size: 14px;
            }

            .button {
              width: 100%;
            }

            .inputs {
              display: flex;
              flex-wrap: wrap;
              width: 100%;

              @include breakpoint(max-width 991px) {
                overflow: hidden;
              }
            }

            .registration-submit-details {
              margin-top: 20px;
              margin-bottom: 0;
              font-size: 12px;
              text-align: left;
              line-height: 22px;
            }

            .input-wrap {
              width: 100%;

              @include breakpoint(992px) {
                width: calc((100% - 30px) / 2);
              }

              &.grec-wrap {
                width: 100%;
                display: flex;
                justify-content: center;

                @include breakpoint(max-width 425px) {
                  justify-content: flex-start;
                }

                .error-message, .form-error {
                  top: calc(100% + 2px);
                  width: 100%;
                  text-align: center;

                  @include breakpoint(max-width 425px) {
                    text-align: right;
                  }
                }

                .form-error {
                  display: block;
                }

                &.not-valid {
                  .error-message {
                    display: block;
                  }
                }
              }

              &.checkbox-wrap {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                margin-top: 20px;

                span.rules {
                  border-bottom: 2px solid #f87e62;
                  margin-bottom: -4px;
                  margin-left: 5px;
                  margin-right: 5px;
                }

                label {
                  div {
                    width: calc(100% - 35px);
                  }
                }
              }

              &.submit-wrap {
                margin-left: auto;
                margin-right: auto;
              }

              &:nth-of-type(2n) {
                margin-left: auto;
              }
            }
          }

          .reg-success {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            pointer-events: none;
            opacity: 0;
            padding: 20px;

            h3 {
              font-size: 24px;
              font-weight: 300;
              margin-bottom: 20px;
            }

            &.active {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
    }
  }
}

.media_message_history {
  .navbar {
    .wrapper {
      .container {
        .history-trigger {
          width: 40px;
          height: 40px;
          display: block;
          background: url("../images/search.png") left center / 18px 18px no-repeat;
          position: relative;

          @include breakpoint(992px) {
            display: none;
          }
        }
      }
    }
  }
}
