.footer {
  padding: 60px 0;
  background-color: #f2f2f2;

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint(max-width 991px) {
      flex-direction: column;
      align-items: center;
    }

    .logo {
      display: inline-block;
      min-width: 78px;
      height: 26px;
      background: url('../images/logo-gray.png') center / cover no-repeat;
      position: relative;

      @include breakpoint(992px) {
        margin-right: 60px;
      }
    }

    a.button {
      @include breakpoint(992px) {
        margin-left: auto;
      }
    }

    ul {
      display: flex;
      margin-top: 20px;

      @include breakpoint(max-width 1199px) {
        flex-wrap: wrap;
      }

      @include breakpoint(max-width 991px) {
        width: 100%;
        max-width: 600px;
        justify-content: center;
        margin: 30px 0;
      }

      li {
        margin-right: 40px;

        @include breakpoint(max-width 1219px) {
          margin-right: 10px;
        }

        @include breakpoint(max-width 991px) {
          margin: 10px 20px;
        }

        a {
          color: $text2;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }

    .gdpr-wrapper {
      display: flex;
      margin-left: 20px;

      @include breakpoint(max-width 991px) {
        margin-top: 25px;
        margin-left: 0;
      }

      img {
        width: auto;
        max-width: 60px;
        min-width: 50px;
        height: 50px;
      }
    }
  }
}
