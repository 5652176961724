.hero {
  padding-top: 90px;
  position: relative;

  @include breakpoint(max-width 991px) {
    padding-top: 60px;
  }

  .hero-shadow {
    position: absolute;
    width: 56.25%;
    height: auto;
    right: 0;
    bottom: 0;

    @include breakpoint(max-width 767px) {
      display: none;
    }
  }

  .container {
    display: flex;
    align-items: center;
    position: relative;

    @include breakpoint(max-width 767px) {
      flex-wrap: wrap;
    }

    @include breakpoint(max-width 1199px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include breakpoint(1200px) {
      height: 540px;
    }

    .hero-left {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include breakpoint(992px) {
        width: 50%;
      }

      @include breakpoint(max-width 767px) {
        width: 100%;
        text-align: center;
        order: 2;
        align-items: center;
      }

      h1 {
        font-size: 60px;
        font-weight: 300;
        color: $text;
        line-height: 70px;
        width: 100%;

        @include breakpoint(max-width 767px) {
          font-size: 36px;
          line-height: 46px;
        }
      }

      h3 {
        font-size: 20px;
        color: $text2;
        font-weight: 300;
        margin: 40px 0;

        @include breakpoint(max-width 767px) {
          font-size: 18px;
          margin: 20px 0;
        }
      }

      .btn-wrap {
        display: flex;
        text-align: center;

        @include breakpoint(max-width 499px) {
          flex-direction: column;
        }

        .send-wrap {
          position: relative;

          .cts {
            position: absolute;
            top: calc(100% + 30px);
            left: 50%;
            transform: translateX(-50%);
            padding-right: 23px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: $text2;

            &.loggedin {
              left: 0;
              transform: translateX(0);
            }

            @include breakpoint(max-width 1219px) {
              top: calc(100% + 10px);
            }

            @include breakpoint(max-width 499px) {
              display: none;
            }

            &:after {
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -4px;
              background: url("../images/down.png") right center / contain no-repeat;
              width: 12px;
              height: 8px;
              transform: rotate(-90deg);
              content: '';
            }
          }
        }

        .login-call {
          margin-left: 10px;

          @include breakpoint(max-width 499px) {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    .hero-right {
      width: 35%;

      @include breakpoint(992px) {
        width: 50%;
        padding: 0 33px 0 100px;
      }

      @include breakpoint(max-width 767px) {
        width: 100%;
        margin-bottom: 30px;
      }

      img, svg {
        width: 100%;
        height: auto;
        display: block;
        overflow: visible;

        @include breakpoint(max-width 767px) {
          max-width: 260px;
          margin: 0 auto;
        }

        .right-in {
          transform-origin: center;
          animation: rightin 0.5s ease-in-out;
        }

        .left-in {
          transform-origin: center;
          animation: leftin 0.5s ease-in-out;
        }

        .st15 {
          transform-origin: center;
          animation: shadow 3s infinite linear;
          opacity: 1;
        }

        .group1, .group2, .group3 {
          opacity: 1;
        }

        .group1 {
          animation-delay: .1s;
          animation: updown 3s infinite ease-in-out;
        }

        .group2 {
          animation-delay: .2s;
          animation: updown 4s infinite ease-in-out;
        }

        .group3 {
          animation-delay: .3s;
          animation: updown 5s infinite ease-in-out;
        }

        .man, .woman {
          animation: updown 3s infinite linear;
        }

        .man {
          display: block !important;
        }

        .woman {
          display: none !important;
        }

        &.woman {
          .man {
            display: none !important;
          }

          .woman {
            display: block !important;
          }
        }
      }
    }
  }
}

@include keyframes(updown) {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

@include keyframes(shadow) {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: .5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@include keyframes(rightin) {
  0% {
    opacity: 0;
    transform: rotate(-10deg) translate(-80%, 70%);
  }
  100% {
    opacity: 1;
    transform: rotate(0) translate(0, 0);
  }
}

@include keyframes(leftin) {
  0% {
    opacity: 0;
    transform: rotate(10deg) translate(80%, -70%);
  }
  100% {
    opacity: 1;
    transform: rotate(0) translate(0, 0);
  }
}

.section {
  padding: 60px 0;
  position: relative;

  @include breakpoint(1200px) {
    padding: 120px 0;
  }

  &.section-features {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-size: 30px;
      line-height: 42px;
      font-weight: 300;
      color: $text;
      text-align: center;
      max-width: 550px;

      @include breakpoint(max-width 767px) {
        font-size: 26px;
        line-height: 34px;
      }
    }

    .features-grid {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include breakpoint(1200px) {
        padding: 0 5%;
      }

      .feature {
        margin-top: 40px;
        position: relative;
        width: 45%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint(992px) {
          width: 30%;
          margin-top: 80px;
        }

        @include breakpoint(1200px) {
          width: 26%;
        }

        @include breakpoint(max-width 767px) {
          width: 100%;
        }

        .img-cont {
          width: 54px;
          height: 50px;
          margin-right: 36px;
        }

        img {
          width: auto;
          height: auto;
          max-height: 50px;
          display: block;
          margin: 0 auto;
        }

        h5 {
          font-size: 18px;
          line-height: 28px;
          color: $text2;
          font-weight: 300;
          width: calc(100% - 90px);
        }
      }
    }
  }

  &.section-slider {
    background-color: $orange;

    .container {
      .owl-carousel {
        .slide {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 80%;
          margin: 0 auto;

          img.qoute {
            width: 94px;
            height: auto;
            display: block;
            margin: -26px 0;
          }

          h2 {
            font-size: 30px;
            color: #ffffff;
            line-height: 42px;
            text-align: center;
            font-weight: 300;
            width: 650px;
            max-width: 90%;
            margin: 60px 0 80px;

            @include breakpoint(max-width 767px) {
              font-size: 20px;
              line-height: 30px;
              margin: 60px 0 40px;
            }
          }

          h5 {
            font-size: 18px;
            color: #ffffff;
            font-weight: 300;
            position: relative;
            padding-top: 35px;
            margin-bottom: 15px;

            &:before {
              position: absolute;
              top: 0;
              left: 50%;
              width: 34px;
              height: 4px;
              margin-left: -17px;
              border-radius: 3px;
              background-color: #fff;
              content: '';
            }
          }

          h6 {
            text-transform: uppercase;
            color: #a52a11;
            font-weight: 700;
            font-size: 12px;
          }
        }

        .owl-prev, .owl-next {
          width: 40px;
          height: 40px;
          background: url("../images/arrow.png") center / cover no-repeat;
          -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 0;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -20px;
          transform: rotate(180deg);
        }

        .owl-next {
          left: auto;
          right: 0;
          transform: rotate(0deg);
        }
      }
    }
  }

  &.section-writes {
    .container {

      h2 {
        font-size: 48px;
        letter-spacing: 0.025em;
        color: $text;
        text-align: center;

        @include breakpoint(max-width 767px) {
          text-align: center;
          font-size: 36px;
        }
      }
    }

    .writes-grid {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 40px;
      flex-wrap: wrap;

      a {
        display: inline-block;
        margin: 20px;

        img {
          display: block;
          max-height: 55px;
          width: auto;
          height: auto;
        }
      }

      &.centering {
        justify-content: center;

        a img {
          max-height: 95px;
          max-width: 200px;
        }
      }
    }
  }

  &.section-benefit {
    background-color: #f5f5f5;

    .img-benefit {
      max-width: 47%;
      height: auto;
      width: auto;
      position: absolute;
      right: 0;
      bottom: 0;
      max-height: 80%;

      @include breakpoint(max-width 991px) {
        max-width: 70%;
        opacity: 0.4;
      }

      @include breakpoint(max-width 767px) {
        display: none;
      }
    }

    .container {
      position: relative;

      .benefit-content {
        font-weight: 300;
        width: 55%;

        @include breakpoint(max-width 991px) {
          width: 85%;
        }

        @include breakpoint(max-width 767px) {
          width: 100%;
        }

        strong {
          font-weight: 400;
        }

        h2 {
          font-size: 48px;
          letter-spacing: 0.025em;
          color: $text;

          @include breakpoint(max-width 767px) {
            text-align: center;
            font-size: 36px;
          }
        }

        h3 {
          font-size: 30px;
          color: $text;
          margin: 50px 0 40px;

          @include breakpoint(max-width 767px) {
            text-align: center;
            font-size: 24px;
          }
        }

        ul {

          li {
            color: $text2;
            position: relative;
            padding-left: 45px;
            line-height: 26px;
            letter-spacing: 0.025em;

            &:not(:last-of-type) {
              margin-bottom: 30px;
            }

            &:before {
              background: url("../images/check.png") center / cover no-repeat;
              width: 14px;
              height: 11px;
              content: '';
              position: absolute;
              left: 0;
              top: 7px;
            }
          }
        }
      }
    }
  }

  &.section-workflow {
    .container {
      h2 {
        font-size: 48px;
        color: $text;
        margin-bottom: 75px;
        letter-spacing: 0.025em;
        text-align: center;
        font-weight: 300;

        strong {
          font-weight: 400;
        }

        @include breakpoint(max-width 767px) {
          text-align: center;
          font-size: 36px;
          margin-bottom: 60px;
        }
      }

      .workflow-grid {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include breakpoint(max-width 767px) {
          flex-direction: column;
          align-items: center;
        }

        .workflow {
          width: 180px;
          height: auto;

          @include breakpoint(max-width 991px) {
            width: 100px;
          }

          @include breakpoint(max-width 767px) {
            width: 160px;
          }

          img {
            width: 100%;
            height: auto;
            display: block;
          }

          h5 {
            font-size: 18px;
            color: $text;
            font-weight: 300;
            letter-spacing: 0.025em;
            text-align: center;
            line-height: 28px;
            margin-top: 40px;

            @include breakpoint(max-width 991px) {
              font-size: 16px;
              line-height: 24px;
              margin-top: 20px;
            }
          }
        }

        .workflow-arrow {
          width: 54px;
          height: 11px;
          display: block;
          margin-top: 84px;

          @include breakpoint(max-width 991px) {
            margin-top: 44px;
          }

          @include breakpoint(max-width 767px) {
            margin: 60px 0;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &.section-price {
    background-color: $orange;
    overflow: hidden;
    padding-bottom: 80px;
    padding-top: 0;

    @include breakpoint(max-width 767px) {
      padding-bottom: 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      @include breakpoint(max-width 767px) {
        flex-direction: column;
      }

      .price-left {
        width: 48%;
        display: flex;
        color: #ffffff;
        background-color: #fff;
        -webkit-box-shadow: 0px 5px 20px 0px rgba(119, 119, 119, 0.15), inset 0px 30px 25px -20px rgba(119, 119, 120, 0.15);
        -moz-box-shadow: 0px 5px 20px 0px rgba(119, 119, 119, 0.15), inset 0px 30px 25px -20px rgba(119, 119, 120, 0.15);
        box-shadow: 0px 5px 20px 0px rgba(119, 119, 119, 0.15), inset 0px 30px 25px -20px rgba(119, 119, 120, 0.15);
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0 40px 80px;

        @include breakpoint(max-width 767px) {
          width: 100%;
          padding: 0 20px 40px;
        }

        img {
          width: 204px;
          height: auto;
          display: block;
        }

        h2 {
          font-size: 30px;
          color: $text;
          margin: 30px 0 0;
        }

        .price {
          font-size: 100px;
          font-weight: 300;
          color: $orange;
          letter-spacing: -0.04em;
          margin: 30px 0 0;

          @include breakpoint(max-width 991px) {
            font-size: 60px;
          }
        }

        h5 {
          font-size: 18px;
          color: $text;
          margin: 30px 0 0;
        }

        .sep {
          height: 4px;
          border-radius: 4px;
          width: 30px;
          background-color: #f2f2f2;
          margin: 30px 0;
        }

        p {
          color: $text2;
          font-weight: 300;
          line-height: 22px;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }

          strong {
            font-weight: 400;
          }
        }

        .button {
          margin-top: 40px;

          @include breakpoint(500px) {
            padding: 0 100px;
          }
        }
      }

      .price-right {
        width: 48%;
        display: flex;
        padding: 0 40px 80px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        color: #ffffff;

        @include breakpoint(max-width 767px) {
          width: 100%;
          padding: 0 20px 40px;
        }

        h5 {
          font-size: 18px;
          margin: 30px 0 0;
        }

        h2 {
          font-size: 30px;
          margin: 10px 0 0;
          max-width: 280px;
          line-height: 40px;
        }

        .price {
          font-size: 100px;
          font-weight: 300;
          letter-spacing: -0.04em;
          margin: 40px 0 0;

          @include breakpoint(max-width 991px) {
            font-size: 60px;
          }
        }

        .sep {
          height: 4px;
          border-radius: 4px;
          width: 30px;
          background-color: #ffffff;
          margin: 40px 0;
        }

        p {
          font-weight: 300;
          line-height: 22px;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }

        .button {
          margin-top: 40px;
        }
      }
    }
  }

  &.section-team {
    .container {

      h2 {
        font-size: 48px;
        color: $text;
        margin-bottom: 75px;
        letter-spacing: 0.025em;
        text-align: center;
        font-weight: 300;

        @include breakpoint(max-width 767px) {
          font-size: 36px;
        }

        strong {
          font-weight: 400;
        }
      }

      .team-grid {
        display: flex;
        justify-content: space-between;

        @include breakpoint(max-width 767px) {
          flex-wrap: wrap;
        }

        .team-member {
          width: 240px;
          height: auto;
          text-align: center;

          @include breakpoint(max-width 991px) {
            width: 170px;
          }

          @include breakpoint(max-width 767px) {
            width: 45%;
            margin-bottom: 30px;
          }

          .member-image {
            width: 100%;
            height: auto;
            display: block;
            max-width: 180px;
            margin: 0 auto;
          }

          h5 {
            font-size: 18px;
            color: $text;
            letter-spacing: 0.025em;
            margin: 40px 0 20px;

            @include breakpoint(max-width 991px) {
              font-size: 16px;
            }
          }

          p {
            color: $text2;
            line-height: 26px;
            letter-spacing: 0.025em;
            min-height: 52px;

            @include breakpoint(max-width 991px) {
              min-height: 78px;
              font-size: 14px;
            }

            @include breakpoint(max-width 767px) {
              min-height: 0;
            }
          }

          .socials {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            a {
              width: 30px;
              height: 30px;
              display: block;
              margin: 0 5px;

              img {
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.section-contact {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 48px;
        letter-spacing: 0.025em;
        font-weight: 300;
        color: $text;
        margin-bottom: 50px;

        @include breakpoint(max-width 767px) {
          text-align: center;
          font-size: 36px;
        }
      }
    }
  }
}
