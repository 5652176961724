.footer-cookie-disclaimer {
  display: none;
  position: fixed;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  padding: 10px 25px;
  margin: 0;
  line-height: 30px;
  background-color: white;
  text-align: center;
  -webkit-box-shadow: 0px -3px 29px -14px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px -3px 29px -14px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -3px 29px -14px rgba(0, 0, 0, 0.5);

  .btn-allow-cookies {
    color: #f65631;
  }
}