.view-message {
  background-color: #f2f2f2;
  padding-bottom: 120px;
  min-height: 100vh;

  @include breakpoint(max-width 991px) {
    padding-bottom: 60px;
  }

  .container {
    .logo {
      width: 78px;
      height: 86px;
      background: url("../images/logo.png") center / contain no-repeat;
      display: block;
      padding: 30px 0;
    }

    .content {
      padding: 120px 17.5%;
      background-color: #fff;
      box-shadow: 0px 0px 23.75px 1.25px rgba(119, 119, 120, 0.07);

      @include breakpoint(max-width 991px) {
        padding: 60px 7.5%;
      }

      @include breakpoint(max-width 499px) {
        padding: 20px;
      }

      .date {
        padding-left: 17px;
        font-weight: 300;
        color: #adadad;
        font-size: 12px;
        background: url("../images/time.png") left center / 9px 9px no-repeat;
      }

      h1 {
        font-size: 30px;
        color: $text;
        line-height: 34px;
        letter-spacing: 0.025em;
        margin: 20px 0 30px;
        font-weight: 300;
      }

      .wysiwyg {
        font-weight: 300;
        color: $text2;
        line-height: 1.625;
        font-size: 16px;

        strong {
          font-weight: 700;
        }

        em {
          font-style: italic;
        }

        >* {
          margin-bottom: 25px;
        }

        ol {
          list-style: decimal;
          padding-left: 22px;
        }

        ul {
          list-style: disc;
          padding-left: 25px;
        }
      }

      .files {
        h5 {
          font-weight: 500;
          font-size: 18px;
          color: $text;
        }

        .files-grid {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px;

          .grid-item {
            width: calc(20% - 20px);
            height: 100px;
            margin: 10px;

            @include breakpoint(max-width 899px) {
              width: calc(25% - 20px);
            }

            @include breakpoint(max-width 767px) {
              width: calc(33.33% - 20px);
            }

            @include breakpoint(max-width 479px) {
              width: calc(50% - 20px);
            }

            @include breakpoint(max-width 349px) {
              height: 80px;
            }

            &:focus {
              outline: none;
            }

            &.image {
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }

            &.notimage {
              border: 1px solid #d4d4d4;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
            }
          }
        }

        a {
          display: block;
          margin-top: 15px;
          color: $text;
          word-break: break-all;
        }
      }

      .info {
        margin-top: 40px;
        padding: 30px 0;
        border-top: 4px solid #f2f2f2;
        border-bottom: 4px solid #f2f2f2;
        display: flex;

        @include breakpoint(max-width 767px) {
          flex-wrap: wrap;
        }

        .info-block {
          width: calc(33.33% - 40px);
          margin-right: 40px;
          display: flex;
          align-items: center;

          @include breakpoint(max-width 1199px) {
            width: calc(33.33% - 20px);
            margin-right: 20px;
          }

          @include breakpoint(max-width 767px) {
            width: 100%;
            margin-right: 0;
            flex-direction: column;

            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }

          .image {
            width: 50px;
            height: 50px;
            margin-right: 10px;

            @include breakpoint(max-width 1199px) {
              width: 30px;
              height: 30px;
            }

            @include breakpoint(max-width 767px) {
              margin-right: 0;
            }
          }

          &.email .image {
            background: url("../images/email.png") center / contain no-repeat;
          }
          &.phone .image {
            background: url("../images/phone.png") center / contain no-repeat;
          }
          &.name .image {
            background: url("../images/user.png") center / contain no-repeat;
          }

          .text {
            width: calc(100% - 60px);

            @include breakpoint(max-width 1199px) {
              width: calc(100% - 30px);
            }

            @include breakpoint(max-width 767px) {
              width: 100%;
              text-align: center;
              margin-top: 10px;
            }

            h6 {
              font-size: 12px;
              color: #adadad;
              font-weight: 300;
              margin-bottom: 5px;
            }

            p, a {
              font-weight: 300;
              font-size: 14px;
              color: $text;
            }
          }
        }
      }
    }
  }
}