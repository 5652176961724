.section-policy {
  h3 {
    font-size: 24px;
    margin-bottom: 22px;
    color: #1c1d1e;
    text-transform: uppercase;
  }

  p {
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #777778;
    margin-bottom: 30px;
  }

  strong {
    color: #1c1d1e;
  }

  a {
    color: #1c1d1e;
  }

  table {
    margin-bottom: 30px;
  }

  th, td {
    border: 1px solid #1c1d1e;
    padding: 10px;
  }

  th {
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
  }

  ul, ol {
    list-style: disc;
    padding-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;

    li {
      line-height: 26px;
      color: $text2;
      font-weight: 300;
    }
  }

  ol {
    list-style: none;
    counter-reset: item;

    ol {
      padding-left: 30px;

      ul {
        list-style: none;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }

    li {
      margin-top: 10px;
    }

    > li {
      position: relative;

      &:before {
        position: absolute;
        right: calc(100% + 5px);
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-weight: 500;
      }
    }
  }

  > ol {
    margin-bottom: 30px !important;
  }
}