.section-faq {
  .container {
    display: flex;
    justify-content: space-between;

    @include breakpoint(max-width 767px) {
      flex-wrap: wrap;
    }

    .acc-sidebar-wrap {
      @include breakpoint(max-width 767px) {
        width: 100%;
      }

      .acc-sidebar {
        width: 200px;

        @include breakpoint(992px) {
          width: 240px;
        }

        @include breakpoint(max-width 767px) {
          width: 100%;
          margin-bottom: 40px;
        }

        li {
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          text-transform: uppercase;
          justify-content: center;
          color: $orange2;
          text-align: center;
          font-weight: 700;
          font-size: 12px;
          background-color: transparent;
          border: 1px solid $orange2;
          cursor: pointer;
          transition: all .4s ease-in-out;
          line-height: 1;
          margin-bottom: 10px;
          border-radius: 25px;

          @include breakpoint(max-width 767px) {
            height: 30px;
            text-align: center;
            justify-content: center;
          }

          @include breakpoint(768px) {
            &.active {
              color: #fff;
              background-color: $orange2;
              -webkit-box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
              -moz-box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
              box-shadow: 0px 5px 20px 0px rgba(248,126,98,0.3);
            }
          }

          @include breakpoint(1200px) {
            &:hover {
              color: #fff;
              background-color: $orange2;
            }
          }
        }
      }
    }

    .accordion {
      width: calc(100% - 240px);

      @include breakpoint(max-width 767px) {
        width: 100%;
      }

      @include breakpoint(992px) {
        width: calc(100% - 340px);
      }

      .section-seperator {
        position: relative;
        margin: 0 0 10px;
        padding-left: 40px;
        padding-right: 90px;

        @include breakpoint(max-width 991px) {
          padding-right: 40px;
        }

        @include breakpoint(max-width 767px) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include breakpoint(max-width 449px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        span {
          display: block;
          color: $orange2;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
        }

        >*:last-of-type:not(:first-of-type) {
          margin-bottom: 60px;
        }

        p, ul {
          margin-top: 25px;
          font-weight: 300;
          line-height: 26px;
          color: $text2;
        }

        ul {
          list-style: disc;
          padding-left: 15px;

          li {
            &:not(:first-of-type) {
              margin-top: 20px;
            }
          }
        }

        &:not(:first-of-type) {
          margin-top: 50px;
        }
      }
    }
  }
}