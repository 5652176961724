.accordion {
  .acc-wrap {
    width: 100%;
    overflow: hidden;
    transition: all .4s ease-in-out;

    &.sub {
      margin-left: 30px;
      width: calc(100% - 30px);
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .acc-head {
      border-radius: 25px;
      background-color: #f5f5f5;
      transition: background .4s ease-in-out;
      padding: 16px 90px 16px 40px;
      font-size: 18px;
      color: $text;
      font-weight: 300;
      line-height: 28px;
      cursor: pointer;
      position: relative;

      @include breakpoint(max-width 767px) {
        padding: 16px 50px 16px 20px;
        font-size: 16px;
      }

      &:after {
        position: absolute;
        right: 40px;
        top: 50%;
        content: '';
        background: url("../images/down.png") center / cover no-repeat;
        width: 12px;
        height: 8px;
        margin-top: -4px;
        transition: all .4s ease-in-out;
      }
    }

    .acc-body {
      padding: 30px 40px 50px;

      @include breakpoint(max-width 767px) {
        padding: 20px 20px 30px;
      }

      p {
        line-height: 26px;
        color: $text2;
        font-weight: 300;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
    }

    &.active {
      .acc-head {
        background-color: #e2e2e2;

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}