.section-contacts {
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .contact-submit-description {
      font-size: 12px;
      margin: 18px 0 0 0;
    }

    h2 {
      font-size: 30px;
      color: #1c1d1e;
      font-weight: 300;
      width: 100%;

      @include breakpoint(max-width 767px) {
        text-align: center;
        font-size: 24px;
      }
    }

    .con-left {
      width: 45%;
      display: flex;
      flex-direction: column;

      @include breakpoint(max-width 767px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
      }

      .offlbl-details {
          width: auto;
      }

      .con-block {
        margin-top: 40px;
        display: flex;
        align-items: center;

        @include breakpoint(max-width 499px) {
          width: 250px;
        }

        >label {
          color: #adadad;
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          display: inline-block;
          width: 110px;
        }

        >.offlbl {
          color: $text;
          font-size: 16px;
          line-height: 20px;
          width: calc(100% - 110px);
          font-weight: 300;
        }

        img {
          width: 80px;
          height: 80px;
          display: block;
          margin-right: 30px;
        }

        .block-right {
          .line {
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }

            .label {
              color: #adadad;
              font-size: 12px;
              font-weight: 300;
              margin-bottom: 5px;
            }

            a {
              color: $text;
              font-size: 18px;
              font-weight: 300;
            }
          }
        }
      }

      .con-block-details {
        margin-top: 30px;
        color: #1c1d1e;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        padding-right: 15px;

        @include breakpoint(max-width 767px) {
          text-align: center;
        }
      }
    }

    .con-right {
      width: 55%;
      position: relative;

      @include breakpoint(max-width 767px) {
        width: 100%;
        margin-top: 40px;
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        opacity: 1;
        transition: opacity .4s ease-in-out;

        &.active {
          opacity: 0;
        }

        .input-wrap {
          width: calc(50% - 10px);

          @include breakpoint(max-width 991px) {
            width: 100%;
          }

          &.textarea-wrap {
            width: 100%;

            textarea {
              height: 140px;
            }
          }
        }

        .button {
          padding: 0 65px;
          margin: 15px auto 0;
          font-family: 'Rubik', sans-serif;
        }
      }

      .form-success {
        position: absolute;
        width: 100%;
        height: 110%;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity .4s ease-in-out;
        z-index: -1;

        &.active {
          opacity: 1;
          pointer-events: auto;
          z-index: 1;
        }

        img {
          width: 150px;
          height: auto;
          display: block;
          margin-bottom: 40px;
        }

        h2 {
          font-size: 30px;
          color: $orange;
          font-weight: 300;
          text-align: center;
        }

        p {
          font-weight: 300;
          font-size: 18px;
          margin-top: 15px;
          color: $text2;
        }
      }
    }
  }
}